.header {

	height: 60px;
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
	text-align: center;
	font-weight: 500;
	position: fixed;
	width: 100%;
	background-color: $c1;
    z-index: 5000;

	&__container {
		max-width: calc(#{$headerContainer} + 4%);
		padding: 0 2%;
	}

	&__inner {
		position: relative;
	}

	&__logo {
		float: left;
		height: 60px;
    	line-height: 60px;
		transition: opacity .3s;

    	&:hover svg {
			fill: $c4;
		}

    	svg {
    		display: inline-block;
    		vertical-align: middle;
    		width: 65px;
			height: 16px;
			fill: $c3;
			transition: fill .3s;
    	}
	}

	&__right {
		font-size: 1.3rem;
		position: absolute;
	    right: 0;
	    top: 0;
		transition: opacity .3s;
	}

	&__nav {
		display: inline-block;
	    margin-right: 5em;
	}

	&__lang,
	&__login {
		display: inline-block;
		line-height: 60px;
		margin: 0;
	}

	&__lang {
		transition: z-index .3s;
		&__item {
			margin-bottom: 0;
			list-style: none;
			&:not(.header__lang__item--active) {
				position: absolute;
				bottom: -60px;
				opacity: 0;
				visibility: hidden;
				transform: translateY(5px);
			    z-index: 5000;
    			transition: all .3s;
			}
			&--active {
				.header__lang__link:before {
					content: '❯';
					transform: rotate(90deg);
					display: inline-block;
					position: absolute;
				    left: 4px;
				    font-size: .75em;
				}
			}
		}
		&__link {
			display: block;
			position: relative;
			text-decoration: none;
			color: $c3;
			padding: 0 1.5em;
    		background-color: white;
		    transition: all .3s;
		    cursor: pointer;
		}
		&:hover,
		&:active {
			z-index: 500;
			position: relative;
			.header__lang__item {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}

		}
	}

	&__login {
		text-decoration: none;
		color: $c3;
	    padding: 0 1.5em;
	    vertical-align: top;
	}

	@media(max-width: $md) {

		&__container {
			max-width: calc(#{$headerContainer} + 8%);
			padding: 0 4%;
		}

		&__lang,
		&__login {
			line-height: 55px;
			color: $c1;
		}

		&__lang {
			&__link {
				background-color: transparent;
				color: $c1;
			}
		}

		&__inner {
			padding-bottom: 60px;
		}

		&__nav {
			margin-right: 0;
		}

		&__logo {
		    position: absolute;
		    bottom: 0;
		    left: 0;

		    svg {
		    	fill: $c1;
		    }
		}

		&__right {
			position: absolute;
		    right: 0;
		    top: 64px;
		}

		&--homepage {
			.header__logo svg {
				fill: $c3;
			}
			.header__login {
				color: $c3;
			}
			.header__lang {
				&__link {
					color: $c3;
				}
			}
		}
	}
}
