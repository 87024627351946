.heading {
	background-color: $c5;	
	@include font(31, 48);    
    padding: 1.675em 0;	
    position: relative;

	&__container {		
		text-align: center;
	}

	&__bg {
	    width: 21%;
	    max-width: 235px;
    	height: 100%;
	    position: absolute;
	    right: 0;
	    top: 0;
        background-size: cover;
    	background-position: right top;
	}

	&__h {
		padding-top: 0;	
		max-width: 750px;
		margin-left: auto;	
		margin-right: auto;	
	}

	&__p {
		font-size: 1.6rem;
		margin-top: 0;
		max-width: 550px;
		margin: auto;
	}

	&--light {
		background-color: $c1;
	}

	&--border {
		border-bottom: 1px dotted $c6;	
	}

	&--no-subheading {
		.heading__h {
			margin-bottom: 0;
		}
	}

	&--no-bg {
		background-color: transparent;
	}

	.logos {
		padding-bottom: 0;
	}
}