.features-list {
	@include font(31, 48);
	padding-left: .6em;
    padding-bottom: 1.675em;
    margin-bottom: 0;
    max-width: 350px;

	&__item {
		font-size: 1.6rem;
		list-style: none;
    	position: relative;

    	&:not(:last-child) {
			margin-bottom: 2em;
    	}

		&:before {
			content: '';
			width: 6px;
			height: 6px;
			background-color: $c8;
		    display: inline-block;
		    position: absolute;
		    left: -24px;
		    top: 12px;
		}
	}

	&__p {
		font-size: 1.4rem;

		strong {
			color: $c8;
		}
	}

	@media(min-width: $md) {
		padding-top: 1.675em;
	}

}
