.cta {
	text-align: center;
	background-color: $c3;
	color: $c1;
	background-size: 34%;
    background-repeat: no-repeat;
    background-position: right;
    position: relative;

    &__container {
		max-width: 800px;
    	margin: 0 auto;
    }

    &__bg {
    	width: 29%;
    	height: 100%;
	    position: absolute;
	    left: 0;
	    top: 0;
        background-size: cover;
    	background-position: left top;
    	max-width: 369px;
    }

    &__bg2 {
    	width: 41.5%;
    	max-width: 490px;
    	height: 100%;
	    position: absolute;
	    right: 0;
	    top: 0;
        background-size: cover;
    	background-position: right 25%;
    }

	&__bg3 {
		width: 29%;
		height: 65%;
		position: absolute;
		left: 0;
		bottom: 0;
		background-size: cover;
		background-position: left top;
		max-width: 369px;
    }

	&__heading {
		color: $c1;
		margin-bottom: .35em;
	}

	&__subheading {
		opacity: .8;
	}

	&__btn {
		margin-bottom: 5.65em;
	    margin-top: 2em;
	}

	&--light {
		color: $c3;
		background: #bfc7c5;
		background-image: linear-gradient(to left, #bfc7c5 0%, #dfe3e2 100%);
		background-size: auto;

		.cta__heading {
			color: $c3;
		}
	}
}
