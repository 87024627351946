.contact-boxes {
	background-color: $c19;
	text-align: center;
	font-size: 0;
	padding-bottom: 1px;
	padding-top: 1px;

	&__box {	
		display: block;
		max-width: 300px;
		margin-bottom: 30px;
		margin: 0 auto;
		position: relative;
		font-size: 16px;
		margin-top: -30px;
		padding: 2em;
		background-color: $c1;
		z-index: 1000;
		box-shadow: 0.7px 0.7px 5px rgba(0, 0, 0, 0.05);
		text-decoration: none;
		color: $c4;
		transition: box-shadow .4s;

		&:first-child {
			margin-bottom: 60px;			
		}
		&:last-child {
			margin-bottom: 30px;			
		}
		&:hover {
			box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
		}
	}

	&__text {
		display: block;
		margin-top: 30px;

		&--bottom {
			position: absolute;
			bottom: 0.2em;
			left: 0;
			right: 0;
			font-size: 14px;
		}
	}

	&__icon {
		vertical-align: middle;		
	}

	@media(min-width: 900px) {
		&__icon {			
			margin-right: 30px;
		}
		&__text {
			display: inline;
			margin-top: 0;
		}
		&__box {
			max-width: none;
			width: 350px;
			padding: 2.9em 1em;
			display: inline-block;
			&:first-child {
				margin-right: 15px;
				margin-bottom: 30px;
			}

			&:last-child {
				margin-left: 15px;
			}
		}
	}
}