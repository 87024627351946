@keyframes pulse {
    0% {
        opacity: 1;
    }
    
    100% {
        opacity: 0.4;
    }
}

.admin-focused {
    position: relative;
}

.admin-focused::before {
    pointer-events: none;
    position: absolute;
    content: " ";
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 0, 0, 0.3);
    z-index: 1000;
    animation: pulse 600ms linear 0s 6 alternate;
}