.link-list {
	@include font(31, 48);
	padding-top: 0;
	padding-left: 0em;
    padding-bottom: 1.675em;
    margin-bottom: 0;
    max-width: 350px;

    &__item {
    	font-size: 16px;
    	list-style: none;
    }

    &__header {
    	padding-top: 1em;
    }

    &__heading {
    	font-weight: 500;
		font-size: 14px;
		color: rgba(255, 255, 255, 0.5);
	    margin-bottom: 1em;
    	float: left;
    }

    &__date {
    	font-size: 14px;
		color: rgba(255, 255, 255, 0.5);
		float: right;
    }

    &__link {
    	font-weight: 800;
    	color: $c1;
    }

	@media(min-width: $md) {
		padding-top: 1.675em;
	}
}
