.job {
	padding: 7%;

	&__heading {
		margin-bottom: 1.75em;
	}

	&__desc {
		margin-bottom: 3em !important;
	}

	&__heading-bottom {
		margin-top: 3em;
	}

	&__btn {
		margin: 0 2em 0 0;
	}

	&__back {
		color: $c25;
	}

	ul {

	}

	li {
		list-style: none;
		position: relative;
		&:before {
		    content: '';
		    width: 6px;
		    height: 6px;
		    background-color: $c8;
		    display: inline-block;
		    position: absolute;
		    left: -24px;
		    top: 8px;
		}
	}
}