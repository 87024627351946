.timeline {
	&__year {
		text-align: left;
		span {
			color: $c1;
			font-weight: 800;
			font-size: 18px;
			width: 65px;
			height: 65px;
			border-radius: 50%;
			background-color: $c4;
			display: inline-block;
			line-height: 65px;
			position: relative;
			text-align: center;

		}
	}

	&__list {
		position: relative;
	    margin-bottom: 10%;
		&:after {
			content: '';
			width: 2px;
			height: 88%;
			/*background-color: $c4;*/
			background: $c4;
			background: linear-gradient(to bottom, rgba($c4,.7) 0%, $c4 100%);
			position: absolute;
			top: 35px;
			left: 32px;
			z-index: -1;
		}
	}


	&__item {
		list-style: none;
		margin-bottom: 5%;
		&:first-child {
			.timeline__title {
				color: lighten($c4,30%);
			}
			.timeline__year span {
				background-color: lighten($c4,30%);
			}
		}
		&:nth-child(2) {
			.timeline__title {
				color: lighten($c4,20%);
			}
			.timeline__year span {
				background-color: lighten($c4,20%);
			}
		}
		&:nth-child(3) {
			.timeline__title {
				color: lighten($c4,10%);
			}
			.timeline__year span {
				background-color: lighten($c4,10%);
			}
		}
	}

	&__text {
		padding-left: 85px;
	}

	&__title {
		margin-top: -2.25em;
		font-size: 20px;
		color: $c4;
		margin-bottom: .5em;
	}

	@media (min-width: 500px) {
		&__text {
			padding-left: 100px;
			padding-right: 100px;
		}
	}

	@media (min-width: $md) {

		&__list {
			&:after {
				left: auto;
				right: calc(50% - 2px);
			}
		}

		&__year {
			text-align: center;
			span {
				text-align: center;
				font-size: 18px;
			}
		}

		&__title {
			margin-top: -2em;
			font-size: 24px;
		}

		&__item {

			&:nth-child(odd) {
				.timeline__text {
					text-align: right;
					padding-left: 0;
					padding-right: calc(65px + 5%);
				}
			}
			&:nth-child(even) {
				.timeline__text {
					padding-left: calc(65px + 5%);
					float: right;
				}
			}
		}

		&__text {
			width: 50%;
			padding-right: 0;
		}
	}
}
