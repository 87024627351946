.product {

	overflow: hidden;
	border-bottom: 1px dotted $c6;

	&__left,
	&__right {
		width: 100%;
	}

	&__container {
		position: relative;
	}

	&__left {
		@include font(31, 48);
		padding: 2.375em 0;
	    display: flex;
	    align-items: center;
		justify-content: center;
	}

	&__text {
		font-size: 16px;
		text-align: center;
		max-width: 100%;

		p {
			margin-bottom: 1em;
		}
	}

	&__logo {
	    margin-bottom: 1em;

		svg {
			max-width: 100%;
		}
	}

	&__right {
		text-align: center;
		padding-bottom: 90px;
	}

	&__img {
	    vertical-align: bottom;
		display: none;
		width: 180%;
		max-width: 200%;
		position: absolute;
		bottom: 3%;

		&--far-right {
			position: absolute;
			right: 0;
			height: 100%;
		}
	}

	&__infobox {
        /*@include font(24, 34);*/
        font-size: 27px;
		background-color: $c4;
        color: $c1;
        font-weight: 900;
        padding: .2em .5em;
        position: absolute;
	    left: 50%;
	    transform: translate(-50%, -50%);
	    /* keep width in chrome */
	    display: table;
	    /* keep width in FF */
	    width: -max-content;
	    width: -moz-max-content;
	    z-index: 500;

        &__number {
        	display: inline-block;
            font-weight: 800;
            letter-spacing: -.05em;
            margin-right: .5em;
        }

        &__text {
            font-size: 10.5px;
            line-height: 1.15;
            display: inline-block;
            text-transform: uppercase;
			text-align: left;
        }
	}

	&--odd {

		background-color: transparent;

		.product__right {
			@include font(31, 48);
			padding: 2.375em 0;
			display: flex;
	    	align-items: center;
		}

		.product__container-inner {
			display: flex;
    		flex-wrap: wrap;
		}

		.product__left {
		    order: 1;
		    padding: 0;
		    display: block;
		    text-align: center;
		    padding-bottom: 90px;
		}

	}

	&--roklen-24 {
		.product__infobox {
			background-color: $c15;
		}
		.product__link {
			color: $c15;
		}
		.product__logo {
			svg {
				fill: $rok24;
			    width: 201px;
	    		height: 34px;
			}
		}
	}

	&--roklen-fx {
		.product__infobox {
			background-color: $c16;
		}
		.product__link {
			color: $c16;
		}
		.product__logo {
			svg {
				fill: $rokFX1;
			    width: 210px;
	    		height: 35px;
			}
		}
		.product__left {
			position: relative;
			z-index:2;
		}
	}

	&--roklen-cf {
		position: relative;
		.product__img--hide-sm {
			display: none;
		}
		.product__img--hide-lg {
			display: block;
		}
		.product__infobox {
			background-color: $c17;
		}
		.product__link {
			color: $c17;
		}
		.product__logo {
			svg {
				fill: $rokCF;
			    width: 350px;
	    		height: 27px;
			}
		}
	}

	&--roklen-fl {
		.product__infobox {
			background-color: $c20;
		}
		.product__link {
			color: $c20;
		}
		.product__logo {
			svg {
			    width: 195px;
	    		height: 40px;
	    		.cls-1 {
					fill: $fundlift1;
				}
				.cls-2 {
					fill: $fundlift2;
				}
			}
		}

	}


	&--basic {
		.product__logo {
			svg {
				fill: $c4;
			}
		}
		.product__link {
			color: $c4;
		}
	}



	@media(min-width: 400px) {
		&__infobox {
	        font-size: 34px;
			/* keep inline chrome, safari */
			white-space: nowrap;

	        &__text {
	            font-size: 12px;
	        }
		}
	}

	@media(min-width: 800px) and (max-width: $lg) {
		&__infobox {
	        font-size: 27px;

	        &__text {
	            font-size: 10.5px;
	        }
		}
	}

	@media(min-width: 800px) {

		&__left,
		&__right {
			lost-column: 1/2 auto 30px;
		}

		&__left {
			/*padding: 0;*/
		}

		&__img {
			display: block;
		}

		&__container-inner {
			display: flex;
		}

		&__right {
			text-align: right;
			padding-top: 5%;
			padding-top: 2vw;
			position: relative;
			padding-bottom: 0;
		}

		&__infobox {
			left: 0;
			bottom: 50px;
			transform: none;
			padding: .2em .75em;
		}

		&__text {
			text-align: left;
		}

		.product__img {
			margin-left: -90px;
		}


		&--odd {

			.product__text {
				text-align: right;
			}

			.product__left {
				padding-top: 5%;
				padding-top: 2vw;
				position: relative;
				text-align: left;
				padding-bottom: 0;
			}

			.product__right {
				/*padding: 0;*/
			}

			.product__infobox {
				transform: none;
				right: -20px;
				left: auto;
			}

			.product__left {
			    order: 0;
			}

			.product__img {
				margin-left: -160px;
			}
		}




		&--roklen-24 {
			.product__img {

			}
		}

		&--roklen-cf {
			.product__left {
				position: relative;
				z-index:2;
			}
			.product__img {
				position: absolute;
				left:  10%;
				height: auto;
				width: 110%;
				margin: 0;
				bottom: 10%;
			}
			.product__img--hide-sm {
				display: block;
			}

			.product__img--hide-lg {
				display: none;
			}

		}

		&--roklen-fl {
			.product__img {

			}
		}

		&--roklen-auctions {
			.product__img {
				margin-top: 110px;

			}
		}

		&--roklen-books {
			.product__img {
				margin-top: 60px;
			}
			.product__left {
				position: relative;
				z-index:2;
			}
		}

		&--roklen-24 {
			.product__left {
				position: relative;
				z-index:2;
			}
			.product__img {
				width: 180%;
				margin-left: -170px;
				bottom: 0;
			}
		}

		&--roklen-cmo {
			.product__infobox {}
			.product__img {
				width: 115%;
				margin-left: -40px;
				margin-bottom: 20px;
			}
		}

	}



}
