.logos {
    list-style: none;
    font-size: 0;
    vertical-align: middle;
    margin: 50px auto 0;
    text-align: center;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;

    &--align-left {
        text-align: left;
        justify-content: flex-start;

        .logos__logo {
            &:first-child {
                padding-left: 0;
            }
        }
    }

    &__logo {
        position: relative;
        background-repeat: no-repeat;
        height: 70px;
        line-height: 70px;
        padding: 0px 17px;
        background-position: center;
        display: inline-block;
        vertical-align: middle;
        display: flex;
        align-items: center;
        &:after {
            content: '';
            width: 2px;
            height: 16px;
            border-right: 1px dotted $c6;
            display: block;
            position: absolute;
            top: calc(50% - 8px);
            right: 0;
        }

        &:first-child {
            /*padding-left: 0;*/
        }

		&:last-child {
            /*padding-right: 0;*/
			&:after {
                display: none;
            }

		}

        img {
            max-width: 100px;
            max-height: 40px;
            display: inline-block;
            vertical-align: middle;
        }
    }

    @media(min-width: $sm) {
    	padding-bottom: 80px;
    }
}
