@media(max-width: $md) {

    .headroom {
        will-change: transform;
        transition: transform 200ms linear;
    }
    .headroom--pinned {
        transform: translateY(0%);
    }
    .headroom--unpinned {
        transform: translateY(-200%);
    }

    .headroom--not-top {

        .header__logo {
            opacity: 0;
        }
        .header__right {
            opacity: 0;
        }

    }

}
