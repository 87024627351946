.hero {
    position: relative;
    padding-top: 60px;
    &__heading {
        font-size: 5rem;
        text-align: left;
        span {
            color: $c4;
        }
    }
    &__link {
        font-size: 16px;
    }
    &__bg {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 35%;
        max-width: 450px;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
    }

    &__bg2 {
        width: 39.8%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 60px;
        background-size: cover;
        background-position: right top;
        display: none;
    }
    &__left {
        padding: 11% 0;
        padding-right: 34.33%;
    }
    &__right {
        position: absolute;
        top: 0;
        right: 0;
        width: 33.333%;
        height: 100%;
        background-position: 20% 50%;
        background-size: cover;
    }
    &__infobox {
        position: absolute;
        bottom: 0;
        left: -50px;
        background-color: $c4;
        color: $c1;
        font-weight: 900;
        @include font(34, 42);
        padding: .3em .75em;
        z-index: 500;
        width: 80%;
        max-width: 320px;
        &__number {
            font-weight: 300;
            font-size: 72px;
            position: absolute;
            line-height: 1em;
        }
        &__text {
            /*
            transition: line-height 1s cubic-bezier(0, 0, 0.2, 1);
            line-height: 11.5;
            height: 4.25em;
            overflow: hidden;
            margin-bottom: -2.18em;*/
            font-size: 11px;
            display: inline-block;
            padding-left: 4em;
            font-weight: 300;
            vertical-align: text-top;

            b {
                font-weight: normal;
                font-weight: 500;
            }

            /*&--visible {
            	line-height: 1.15;
            }*/
        }
    }


    @media(min-width: 450px) {
        &__heading {
            // text-align: center;
        }
        &__bg2 {
            display: block;
        }
    }

    @media(min-width: $md + 1) {
        &__heading {
            text-align: left;
        }
        &__bg2 {
            display:none;
        }
    }

    @media(max-width: $md) {
        padding-top: 120px;
        &__bg {
            background-image: none !important;
        }
        &__infobox {
            position: static;
            left: 0;
            width: 100%;
        }

        // &__heading {
        //     text-align: center;
        // }

        &__left {
            padding-right: 0;
        }

        &__right {
            position: static;
            width: 100%;
        }
    }
}
