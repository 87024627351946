.secondary-nav {

	&__list {
		font-size: 0;
		margin: 0;
		text-align: center;
	}

	&__container {
		padding: 0;
	}

	&__item {
		width: 50%;
		display: inline-block;
		margin: 0;
		position: relative;
		overflow: hidden;
		font-size: 13px;
		text-align: center;
		background-color: black;
    	height: 85px;
    	border-bottom: 1px solid $c21;
    	overflow: hidden;
    	transition: background-color .3s;

		&:first-child {
			display: none;
		}

    	&:nth-child(odd) {
    		border-right: 1px solid $c21;
    	}

    	&--active {
    		background-color: $c19;
		    border: none !important;
    		&:after {
    			display: none !important;
    		}
    		.secondary-nav__link {
    			color: $c3;
    		}
    	}

    	&:hover {
    		background-color: $c19;
			border: none !important;
    		&:after {
    			background-color: $c19;
    		}
    		.secondary-nav__link {
    			color: $c3;
    		}
    	}
	}

	&__inner {
	    display: table;
    	height: 85px;
	    width: 100%;
	}

	&__link {
		display: table-cell;
		vertical-align: middle;
    	padding: 0 2.5em;
    	text-transform: uppercase;
    	color: $c13;
    	text-decoration: none;
	}


	@media(min-width: $sm) {
		&__item {
			width: 33.333%;
			&:first-child {
				display: inline-block;
			}
			&:nth-child(2),
			&:nth-child(4) {
	    		border-right: 1px solid $c21;
	    	}
		}
	}

	@media(min-width: $md) {
		margin-top: -85px;
		&__container {
			padding: 0 8%;
		}
	}

	@media (max-width: $lg - 1) {
	    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, $c5 49%, $c5 50%, $c5 100%);
	}




	@media(min-width: $lg) {
		&__item {
			width: 16.666%;
			border: none !important;
			&:after {
	    		content: '';
	    		width: 1px;
	    		height: 55px;
	    		background-color: $c21;
	    		position: absolute;
	    		right: 0;
	    		top: 15px;
	    		transition: background-color .3s;
	    	}
		}
	}
}
