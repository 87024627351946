@mixin font($min, $max, $property: font-size, $start: 320, $end: 1280, $clip: true, $clipAtStart: true, $clipAtEnd: true) {
  $multiplier: ($max - $min) / ($end - $start) * 100;
  $adder: ($min * $end - $max * $start) / ($end - $start);
  $formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});
  @if $clip and $clipAtStart {
    @media (max-width: #{$start + 0px}) {
      #{$property}: $min + 0px;
    }
  }
  @if $clip and $clipAtEnd {
    @media (min-width: #{$end + 0px}) {
      #{$property}: $max + 0px;
    }
  }
  #{$property}: $formula;
}