@lost gutter 30px;


$c1: #fff;
$c2: #fafafa;
$c3: #3f4444;
$c4: #5f269f;
$c5: rgba(191, 199, 197, 0.2);
$c6: #d1d3d1;
$c7: #cfd0d0;
$c8: #00c489;
$c9: #e6e8e7;
$c10: #212322;
$c11: rgba(95, 38, 159, 0.35);
$c12: #212322;
$c13: #a3acab;
$c14: rgba(95, 38, 159, 0.9);
$c15: #c6003e;
$c16: #eb3cac;
$c17: #87714d;
$c18: #ff585d;
$c19: #f2f4f3;
$c20: #ea0;
$c21: #1c1d1c;
$c22: rgba(242, 244, 243, 0.5);
$c23: rgba(0, 0, 0, 0.05);
$c24: rgba(0, 0, 0, 0.08);
$c25: #919e9e;


$rokCollege: #F4364D;
$rokFX1: #EB3CAC;
$rokFX2: #d64c94;
$rok24: #C6003E;
$rok360: #572a86;
$fundlift1: #f6b221;
$fundlift2: #0d2240;
$rokPro: #c6003e;
$rokCF: #87714d;


$sm: 587px;
$md: 768px;
$lg: 1024px;
$headerBreak: $md;
$headerContainer: 1220px;
$footerContainer: 1160px;
$container: 900px;
$containerWide: 1110px;
$containerNarrow: 730px;



@import 'mixins';
@import 'normalize';
@import 'milligram';


@import "admin-focus";
@import 'accordion';
@import 'btn';
@import 'contact-boxes';
@import 'contact-info';
@import 'cta';
@import 'dl-links';
@import 'job';
@import 'features';
@import 'features-list';
@import 'font';
@import 'modal';
@import 'footer';
@import 'header';
@import 'hero';
@import 'hero-global';
@import 'heading';
@import 'link-list';
@import 'logos';
@import 'nav';
/*@import 'odometer';*/
@import 'product';
@import 'product-list';
@import 'secondary-nav';
@import 'team';
@import 'timeline';
@import 'two-col';
@import 'headroom';
// @import 'unslider';
