.footer {

	background-color: $c10;
	@include font(30,60);
	padding: 1em 0;

	&__container {
		max-width: calc(#{$footerContainer} + 8%);
		    display: flex;
		    justify-content: space-between;
	}

	&__nav {
		font-size: 0;
		display: inline-block;

		&__list {
			list-style: none;
			margin-bottom: 0;
		}

		&__item {
			margin-bottom: 0;
		}

		&__link {
			color: $c1;
			font-weight: 500;
			text-transform: uppercase;
			font-size: 1.3rem;
			text-decoration: none;
		    font-size: 1.3rem;
    		line-height: 2.4rem;

			&:hover {
				color: $c8;
			}
		}

	}


	&__right {
		text-align: right;
		margin-bottom: 0;
		list-style: none;
		position: relative;
		min-width: 200px;
		float: right;
		min-height: 145px;

		&__item {
			color: $c1;
			font-weight: 500;
			text-transform: uppercase;
			font-size: 1.3rem;
			text-decoration: none;
		    font-size: 1.3rem;
    		line-height: 2.4rem;
    		margin-bottom: 0;

    		&:nth-child(4),
			&:nth-child(5) {
    			position: absolute;
    			bottom: 0;
    			right: 0;

				a {
					color: $c1;
					text-decoration: none;
					&:hover {
						color: $c8;
					}
				}
    		}

			&:nth-child(4) {
				bottom: 2em;
			}

			a {
				&:hover {
					color: $c1;
				}
			}
		}
	}

	@media(max-width: 450px) {
		&__container {
		    flex-direction: column;
		}
		&__nav {
			text-align: center;
		}
		&__right {
			text-align: center;
			margin-top: 35px;
			&__item {

				&:nth-child(4),
				&:nth-child(5) {
	    			position: static;
	    		}
				&:nth-child(4) {
					margin-top: 35px;
				}
			}
		}
	}
}
