.product-list {

	background-color: $c5;
	position: relative;

	.mix {
		/* mixitup plugin */
		display: none;
	}

	&__container {
		max-width: 1280px;
		padding: 0;
		margin: 0 auto;
	}
	&__list {
		font-size: 0;
		margin: 0;
	    display: flex;
    	flex-wrap: wrap;
	    position: relative;
	}
	&__item {
		border: 1px dotted $c6;
		background-color: $c1;
		width: 100%;
		text-align: center;
		min-height: 260px;
    	padding: 30px;
    	margin: 0;
		margin-bottom: -1px;
    	display: table;
    	float: left;
    	display: flex;

    	/*@supports (display:flex) {
 			min-height: 0;
		}*/

    	&:hover,
		&:active {

			-webkit-tap-highlight-color: rgba(0,0,0,0);
			-webkit-user-select: none;
			-webkit-touch-callout: none;

    		.product-list__inner {
				&:before {
					opacity: 1;
				}
			}

			.product-list__logo {
				svg {
					fill: $c1 !important;
				}
				.cls-1 {
					fill: $c1 !important;
				}
				.cls-2 {
					fill: $c1 !important;
				}
			}

			.product-list__btn {
				z-index: 1;
				opacity: 1;
			}

			.product-list__perex {
				opacity: 0;
			}

    	}

		&--fx {
			.product-list__logo {
				.cls-1{fill:$rokFX1;}
				.cls-2{fill:$rokFX2;}
			}
			.product-list__inner {
				&:before {
					background-color: $rokFX1;
				}
			}
			&:hover {
				.product-list__btn {
			    	color: $rokFX1;
				}
			}
		}

		&--fundlift {
			.cls-1 {
				fill: $fundlift1;
			}
			.cls-2 {
				fill: $fundlift2;
			}
			.product-list__inner {
				&:before {
					background-color: $fundlift2;
				}
			}
			&:hover {
				.product-list__btn {
			    	color: $fundlift2;
				}
			}
		}

		&--roklen24 {
			.product-list__logo {
				svg {
					fill: $rok24;
				}
			}
			.product-list__inner {
				&:before {
					background-color: $rok24;
				}
			}
			&:hover {
				.product-list__btn {
			    	color: $rok24;
				}
			}
		}

		&--roklen360 {
			.product-list__logo {
				svg {
					fill: $rok360;
				}
			}
			.product-list__inner {
				&:before {
					background-color: $rok360;
				}
			}
			&:hover {
				.product-list__btn {
					color: $rok360;
				}
			}
		}

		&--basic {
			.product-list__logo {
				svg {
					height: 22px;
					fill: $c4;
				}
			}
			.product-list__inner {
				&:before {
					background-color: $c4;
				}
			}
			&:hover {
				.product-list__btn {
					color: $c4;
				}
			}
		}

		&--roklenPro {
			.product-list__logo {
				svg {
					fill: $rokPro;
				}
			}
			.product-list__inner {
				&:before {
					background-color: $rokPro;
				}
			}
			&:hover {
				.product-list__btn {
			    	color: $rokPro;
				}
			}
		}

		&--roklenCF {
			.product-list__logo {
				svg {
					fill: $rokCF;
				}
			}
			.product-list__inner {
				&:before {
					background-color: $rokCF;
				}
			}
			&:hover {
				.product-list__btn {
			    	color: $rokCF;
				}
			}
		}

		&--roklenCollege {
			.product-list__logo {
				svg {
					fill: $rokCollege;
				}
			}
			.product-list__inner {
				&:before {
					background-color: $rokCollege;
				}
			}
			&:hover {
				.product-list__btn {
			    	color: $rokCollege;
				}
			}
		}
	}
	&__inner {
		position: relative;
	    padding: 40px 30px;
        display: table-cell;
    	vertical-align: middle;
	    display: flex;
	    flex-wrap: wrap;
	    align-items: center;
	    align-content: flex-start;
	    justify-content: center;
	    height: 100%;
	    width: 100%;
	    min-height: 200px;
    	&:before {
    		content: '';
    		position: absolute;
    		width: 100%;
    		height: 100%;
    		top: 0;
    		left: 0;
    		background-color: $c4;
    		opacity: 0;
    		transition: opacity .5s;
    	}
	}
	&__wrap {
		display: table-cell;
		vertical-align: middle;
	}
	&__text {
		position: relative;
		font-size: 1.6rem;
		/* IE10 */
    	-ms-flex: 0 1 auto;
	    width: 100%;
	}
	&__perex {

		transition: opacity .5s;
	}
	&__logo {
		svg {
			position: relative;
			margin-bottom: 25px;
			max-width: 100%;
			transition: fill .5s;
		}
	}
	&__btn {
		margin: 0;
	    position: absolute;
	    top: 25px;
	    left: 50%;
	    transform: translateX(-50%);
	    z-index: -1;
	    opacity: 0;
	    background: none;
	    background-color: $c1;
	    color: $c15;
	    transition: all .5s;
	    &:before {
	    	display: none;
	    }
	}


	@media(min-width: 650px) {
		&__item {
			width: 50%;
			&:nth-child(odd) {
				margin-right: -1px;
			}
		}
	}

	@media(min-width: $lg) {
		&__list {
			margin-bottom: 3px;
		}
		&__item {
			width: 33.333%;
			&:nth-child(even) {
				margin-right: -1px;
			}
		}
	}
}
