.contact-info {
	@include font(31, 48);
	padding-top: 1.675em;	
    padding-bottom: 1.675em;

    &__text {
    	font-size: 14px;
    	text-align: center;
    }

    &__heading {
    	font-size: 1.7em;

    	strong {
    		font-weight: 800;
    	}
    }

    &__tel {
    	margin-top: 1em;
    }

    p {
    	margin-bottom: 0;
    }

    &__btn {
    	margin-bottom: 0
    }
}