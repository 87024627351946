/*@font-face {
    font-family: 'got-med';
    src: url('../fonts/Gotham-Medium.eot');
    src: url('../fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/Gotham-Medium.woff2') format('woff2'), url('../fonts/Gotham-Medium.woff') format('woff'), url('../fonts/Gotham-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'got-black';
    src: url('../fonts/Gotham-Black.eot');
    src: url('../fonts/Gotham-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/Gotham-Black.woff2') format('woff2'), url('../fonts/Gotham-Black.woff') format('woff'), url('../fonts/Gotham-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'got-light';
    src: url('../fonts/Gotham-Light.eot');
    src: url('../fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/Gotham-Light.woff2') format('woff2'), url('../fonts/Gotham-Light.woff') format('woff'), url('../fonts/Gotham-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}*/


body {
    color: $c3;
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 1.6em;
    line-height: 1.5625;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 2em;
    margin-top: 0;
    color: $c12;
    font-weight: 300;
}

h1 {
    @include font(45,72);
    line-height: 1.125;
    margin-bottom: .5em;
    letter-spacing: -0.015em;
}

h2 {
    @include font(31,
    48);
    line-height: 1.25;
    padding-top: 1.675em;
    margin-bottom: .8em;
    letter-spacing: 0;
}

h3 {
    @include font(20,
    24);
    font-weight: 800;
    letter-spacing: .025em;
    line-height: 1.25;
    margin-bottom: .25em;
}

h4 {
    @include font(15,
    16);
    font-weight: 800;
    letter-spacing: .025em;
    line-height: 1.35;
    margin-bottom: 1.25em;
}

h5 {
    font-size: 1.8rem;
    letter-spacing: -0.05rem;
    line-height: 1.5;
}

h6 {
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 1.4;
}
