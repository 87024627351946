.nav {

	&__list {
		margin: 0;
		font-size: 0;
	}

	&__item {
		display: inline-block;
		height: 60px;
		margin: 0;
		font-size: 13px;
		text-transform: uppercase;
		&--active {
			.nav__link {
				color: $c13;
				font-weight: 800;
				&:hover {
					color: $c13;
				}
			}
		}
	}

	&__link {
		display: block;
		line-height: 65px;
		padding: 0 .8em;
		text-decoration: none;
		color: $c3;
		&:hover {
			color: $c4;
		}

	}

	@media(min-width: 350px) {
		&__link {
			padding: 0 1em;
		}
	}

	@media(max-width: $md) {
		&__item {
			/*width: 33.333%;*/
		}
		&__item {
			&:first-child {
				display: none;
			}
		}
	}
}
