.team {
	background-color: $c5;
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: right top;
    position: relative;

    &__bg {
    	width: 28.8%;
    	max-width: 369px;
    	max-height: 50%;
    	height: 100%;
	    position: absolute;
	    right: 0;
	    top: 0;
        background-size: cover;
    	background-position: right top;    	
    }

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 16% 0 0;
		padding: 0;
	}
	&__item {
		width: 100%;
		max-width: 300px;	    
		margin-bottom: 30px;
		list-style: none;		
		&:hover,
		&:active {
			.team__desc {
				opacity: 1;
			}
		}
	}
	&__figure {
		position: relative;
	    margin: 0;
	    /*height: 255px;*/
	    padding-bottom: 100%;
	    position: relative;
	    margin-bottom: 60px;
	    background-position: center;
	    background-size: cover;
	    background-color: #5f269f;

	    &:before {
	    	content: '';
	    	position: absolute;
	    	top: 0;
	    	left: 0;
	    	width: 100%;
	    	height: 100%;
	    	background-image: linear-gradient(to top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.65) 0%, transparent 70%, transparent 100%);
	    }
	}
	&__figcap {
	    position: absolute;
	    bottom: -60px;
	    width: 100%;
	    height: 60px;
	    line-height: 60px;
	    background-color: $c1;
	    padding-left: 9.5%;
	}
	&__desc {		
		/*opacity: 0;*/
		font-weight: 500;
		font-size: 12px;		
		line-height: 1.4166;
		bottom: 9.5%;
	    top: 9.5%;
	    left: 9.5%;
	    right: 9.5%;
	    position: absolute;
	    background-color: $c14;
	    color: $c1;
	    padding: 9.5%;
        transition: opacity .5s;
    	opacity: 0;
	}
	&__name {
	    position: absolute;
	    bottom: 0;
	    margin: 0;
	    padding: 9.5%;
	    color: $c1;
	}
	&__desc-link {
		font-size: 16px;		
		text-decoration: underline;
		color: $c1;
		position: absolute;
		bottom: 0;
		right: 0;
		padding: 9.5%;
	}

	@media(max-width: 599px) {		
		&__item {
			margin: 0 auto 30px;			
		}
	}

	@media(min-width: 600px) {
		&__list {
			margin: 8% 0 0;
		}
		&__item {			
			lost-column: 1/2;
		}
	}

	@media(min-width: 850px) {
		&__item {
			lost-column: 1/3;
		}
	}

	@media(min-width: 1100px) {
		&__item {
			lost-column: 1/4;
		}
	}
}