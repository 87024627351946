.two-col {

	position: relative;

	&:after {
		content: '';
	}

	&__container {
	    display: flex;
	    flex-wrap: wrap;
	    position: relative;
	}

	&__col {
		width: 100%;
	}

	&--grey {

		position: relative;

		.two-col__container {
			&:after {
		    	content: '';
		    	display: block;
		    	position: absolute;
		    	bottom: -15px;
	    		right: calc(50% - 15px);
		    	width: 0;
				height: 0;
				border-style: solid;
				border-width: 15px 15px 0 15px;
				border-color: $c5 transparent transparent transparent;
		    }
		}

		&:before {
		    content: '';
		    display: block;
		    position: absolute;
		    width: 100%;
		    height: 100%;
		    top: 0;
		    z-index: -1;
		}

		&:before {
			background-color: $c5;
		}

		&:after {
			right: 0;
			background: linear-gradient(135deg, $c9 0%, $c5 100%);
		}

		.two-col__col {
			&:first-child {
				border: none;
			}
			&:last-child {

			}
		}
	}

	&--dark {
		background-color: $c3;
		color: $c1;

		.two-col__heading {
			color: $c1;
		}

	}


	@media(min-width: $md) {
		&__col {
			lost-column: 1/2 auto 0;
			&:first-child {
				border-right: 1px dotted $c6;
				padding-right: 6%;
			}
			&:last-child {
				padding-left: 6%;
			}
		}

		&--grey {

			&:after {
			    content: '';
			    display: block;
			    position: absolute;
			    width: 50%;
			    height: 100%;
			    top: 0;
			    z-index: -1;
			    right: 0;
		 	    background: linear-gradient(180deg, $c9 0%, $c5 100%);
			}

			&:before {
				width: 50%;
			}

		}

		&--dark {
			.two-col__col {
				&:first-child {
					border-right: 1px dotted rgba(0,0,0,0.25);
				}
			}
		}
	}

	@media(min-width: $lg) {
		&__col {
			&:first-child {
				padding-right: 12%;
			}
			&:last-child {
				padding-left: 12%;
			}
		}
	}
}
