#warning-modal {
  display: none;
  z-index: 9999;
}

#warning-modal.is-open {
  display: block;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__container {
  background-color: #fff;
  padding: 3rem;
  max-width: 800px;
  margin: 2rem;
  max-height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 3.4rem;
  padding-top: 0;
  color: $c14;
  box-sizing: border-box;
}

.modal__close {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 24px;
  right: 27px;
  color: black;
  box-shadow: none;
  font-size: 3.4rem;

  &::before {
    position: static;
    background: none;
    width: auto;
    height: auto;
    opacity: 1;
  }

  &::after {
    display: none;
  }

  &:hover {
    color: black;
  }
}

.modal__header .modal__close:before { content: "\1F7A8"; }

.modal__content {
  margin-top: 5rem;
  margin-bottom: 0rem;
  line-height: 1.5;
  color: black;
  font-size: 1.8rem;

  a {
    color: $c14;
    outline: none;
  }
}


/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes mmfadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
  to { transform: translateY(0); }
}

@keyframes mmslideOut {
  from { transform: translateY(0); }
  to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}
