.features {

	background-color: $c5;
	padding: 0;

	&--show-icon &__ico {
		display: block;
	    margin-left: auto;
	    margin-right: auto;
	}

	&__container {
		max-width: 1280px;
		padding: 0;
	}

	&__list {
		list-style: none;
		font-size: 0;
		margin-bottom: 0;
	    display: flex;
    	flex-wrap: wrap;
    	justify-content: center;
	}

	&__item {
		display: inline-block;
		width: 100%;
		@include font(16, 24);
		padding: 1em;
		margin: 0;
		border-top: 1px dotted $c6;
		border-bottom: 1px dotted $c6;
	    margin-top: -1px;
	}

	&__inner {
		flex: 1;
		text-align: center;
		transition: background-color .3s;

		&:hover {
			background-color: $c1;
			.features__ico {
				.color {
					stroke: $c8 !important;
				}
			}
			.features__heading {
				span {
					color: $c3;
				}
			}
		}
	}

	&__ico {
		display: none;
	}

	svg {
		height: 100px;
		max-height: 68px;
		margin-bottom: 25px;
	    align-self: center;
	}

	&__link {
		font-weight: 500;
		text-decoration: none;
		color: $c4;
		display: flex;
	    flex-wrap: wrap;
	    /*justify-content: center;*/
        flex-direction: column;
	    padding: 1em;
	}

	&__heading {
		margin-bottom: 0;
	    font-weight: normal;
	    text-decoration: underline;
	    color: $c7;
	    font-size: 2.4rem;
	    font-weight: 500;
	    span {
	    	color: $c4;
	    }
	}

	@media(min-width: $sm) {
		&__item {
			width: 50%;
			&:nth-child(odd) {
				border-right: 1px dotted $c6;
			}
			&:last-child {
				border-left: 1px dotted $c6;
			}
		}
	}

	@media(min-width: $md) {
		&__item {
			width: 33.3333%;
			&:nth-child(2) {
				border-right: 1px dotted $c6;
			}
			&:nth-child(3) {
				border-right: none;
			}
			&:nth-child(4) {
				border-left: 1px dotted $c6;
				border-right: 1px dotted $c6;
			}
		}
	}

	@media(min-width: $lg) {
		&__item {
			width: 20%;
			&:nth-child(odd) {
				border-right: none;
			}
			&:first-child {
				border-right: 1px dotted $c6;
			}
			&:first-child {
				border-left: 1px dotted $c6;
			}
			&:last-child {
				border-right: 1px dotted $c6;
			}
		}
	}

}
