.btn,
button,
input[type='btn'],
input[type='reset'],
input[type='submit'] {
    position: relative;
    border: none;
    border-radius: 0;
    color: $c1;
    cursor: pointer;
    display: inline-block;
    @include font(13, 16);
    font-weight: normal;
    font-weight: 500;
    letter-spacing: 0.05em;
    padding: 1.15em 1.875em 1.1em;
    margin: 3.75em auto;
    line-height: 1em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    transition: background-color .3s;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
    background: $c8;
    background-image: linear-gradient(105deg, #00c489 0%, #00c489 0%, #00ad79 100%);
    transition: all .3s;
    z-index: 1;
    &:after {
        content: '\00BB';
        margin-left: 1em;
    }
    &:before {
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(105deg, #00b07b 0%, #00b07b 0%, #009c6d 100%);
        opacity: 0;
        transition: opacity .3s;
        z-index: -1;
    }

}

/*.btn {
    &--var2 {
        background: $c8;
        background-image: linear-gradient(105deg, #00b07b 0%, #00b07b 0%, #009c6d 100%);
    }
    &--var3 {
        background: $c8;
        background-image: linear-gradient(105deg, #009d6e 0%, #009d6e 0%, #008a61 100%);
    }
    &--var4 {
        background: none;
        background-color: $c4;
    }
}*/

.btn__wrap {
    text-align: center;

    &--grey {
        background-color: $c5;
    }
}

.btn:hover,
button:hover,
input[type='btn']:hover,
input[type='reset']:hover,
input[type='submit']:hover {

    color: #fff;
    outline: 0;
    &:before {
        opacity: 1;
    }

}


.btn:focus,
button:focus,
input[type='btn']:focus,
input[type='reset']:focus,
input[type='submit']:focus,
.btn:active,
button:active,
input[type='btn']:active,
input[type='reset']:active,
input[type='submit']:active {
    color: #fff;
    outline: 0;
    &:before {
        background-image: linear-gradient(105deg, #009d6e 0%, #009d6e 0%, #008a61 100%);
        opacity: 1;
    }
}

.btn.btn--secondary,
button.btn--secondary,
input[type='btn'].btn--secondary,
input[type='reset'].btn--secondary,
input[type='submit'].btn--secondary {
    border: 2px solid $c8;
    padding: 1.05em 1.875em .95em;
    color: $c8;
    background: none;
    background-color: transparent;
    box-shadow: none;
    &:before {
        display: none;
    }
}

.btn.btn--secondary:hover,
.btn.btn--secondary:focus,
button.btn--secondary:hover,
button.btn--secondary:focus,
input[type='btn'].btn--secondary:hover,
input[type='btn'].btn--secondary:focus,
input[type='reset'].btn--secondary:hover,
input[type='reset'].btn--secondary:focus,
input[type='submit'].btn--secondary:hover,
input[type='submit'].btn--secondary:focus {
    color: $c12;
    background-color: transparent;
    border-color: $c12;
}
