.hero-global {

	padding-top: 60px;
	text-align: center;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
	color: $c1;
	z-index: -1;
	
	&--products{
		background-position: center 20%;
	}

	&__container {
		@include font(30,50);
	    padding-top: 2.1em;
    	padding-bottom: 2.75em;
		position: relative;
		max-width: calc(850px + 8%);
	}

	&__heading {
		color: $c1;
		margin-bottom: 0;
	}

	&__perex {
		margin-top: 1em;
		@include font(14,16);
	}

	@media(min-width: $md) {
		margin-top: 0;

		&--about{
			background-position: center;
		}

		&--products {
			.hero-global__container {
				padding-bottom: calc(2.25em + 85px);
			}
		}
	}

}
