.accordion {

	background-color: $c5;

	&__heading {
		text-align: center;
	}

	&__list {
		margin: 0;
		padding: 5% 0 15%;    	
	}

	&__item {
		margin-bottom: 0;
		list-style: none;
		background-color: $c1;
	}
	
	&__link {
		display: block;
		position: relative;
    	padding: 1.45em 24px;
	    padding-right: 65px;
    	border-bottom: 1px solid $c24;
    	font-weight: 100;
    	transition: background-color .3s;

    	&--open,
    	&:hover {
    		background-color: $c22;
    	}
        
        &--title {
            font-weight: 800;
            font-size: 1.2em;
        }
	}

	&__plus {		
	    position: absolute;
	    right: 1em;
	    top: calc(50% - 13px);
		color: $c3;
		font-size: 32px;
		font-weight: normal;
		font-family: got-light;
		line-height: .8em;
		transition: transform .3s;

		&--open {
			transform: rotate(45deg);
		}
	}

	&__content {
		display: none;
	}

	@media(min-width: 650px) {
		&__link {
            padding: 1.45em 47px;
            &--title {
			 padding: 1.45em 37px;                
            }
		}
	}

}