.dl-links {
	margin: 0;
	list-style: none;

	&__item {
		display: flex;
		flex-direction: column-reverse;
	    align-items: flex-start;
		margin-bottom: 0;
		min-height: 72px;
		font-size: 1.4rem;
		padding: 1.05em 1.7em;		
		background-color: $c22;
		border-bottom: 1px solid $c23;
	}

	&__desc {
		font-weight: 500;
		margin-bottom: 1em !important;
	}

	&__link {
		float: right;
	}
	
	&__date {
		display: none;
	}

	@media(min-width: 650px) {
		&__item {
		    flex-direction: row-reverse;
		    align-items: center;
		    justify-content: space-between;
			padding: 1.05em 34px 1.05em 57px;
		}
		&__desc {			
			max-width: 60%;
			display: inline-block;
			margin-bottom: 0 !important;
		}
		&__info {
			float: right;
			text-align: right;
		}
	}

}